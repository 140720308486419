let ModalLoadLibraryComponent = function(
    FormBuilderService,
    MediaService,
) {
    let $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.mediaService = MediaService;

        $ctrl.form = FormBuilderService.build({
            libraryImg: [],
            multiple: $ctrl.modal.scope.multiple
        }, (form) => {
            form.values.sel_medias = [];
            
            form.values.sel_medias = form.values.libraryImg.data.filter(
                media => media.active
            );

            if(!form.values.multiple){
                form.values.sel_medias = form.values.sel_medias[0];
            }

            $ctrl.modal.scope.success(form.values);

            $ctrl.close();
        });
    };

    $ctrl.toggleMediaState = function(media) {
        let $ctrl = this;

        media.active = !media.active;

        if(!$ctrl.form.values.multiple){
            //Deselect other medias

            let medias = $ctrl.form.values.libraryImg.data.filter( item => item.id != media.id );

            medias.map( media => media.active = false );
        }
    }

    $ctrl.$onDestroy = function() { };
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'FormBuilderService',
        'MediaService',
        ModalLoadLibraryComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-load-library.html';
    }
};