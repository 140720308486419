let UploadFileComponent = function(
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.$onInit = () => {};

    $ctrl.$onDestroy = function() { };
};

module.exports = {
    controller: [
        'FormBuilderService',
        UploadFileComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/pages/upload-file.html';
    }
};