let FilesSelectorDirective = function(
    $scope,
    $element,
    FileService,
    ModalService,
) {
    let input = false;

    if (!$scope.files) {
        $scope.files = [];
    }

    $scope.fileEdit = (file) => {
        ModalService.open('fileEdit', {
            file: file
        });
    };

    $scope.fileDelete = (file) => {
        if (!Array.isArray($scope.files)) {
            $scope.files = [];
        }

        let index = $scope.files.indexOf(file);

        if (index != -1) {
            $scope.files.splice(index, 1);

            FileService.delete(file.uid).then(res => {
                $scope.change({
                    media_ids: $scope.files.map(file => file.id)
                });
            });   
        }
    };

    $scope.selectFile = (e) => {
        e && e.preventDefault() && e.stopPropagation();

        if (input && input.remove) {
            input.remove();
        }

        input = document.createElement('input');
        input.setAttribute("type", "file");
        input.setAttribute("accept", "*");
        input.setAttribute("multiple", "");
        input.style.display = 'none';

        input.addEventListener('change', function(e) {
            for (let index = 0; index < e.target.files.length; index++) {
                const file = e.target.files[index];

                FileService.store($scope.type, file).then(res => {
                    let file = res.data.data;

                    if(file){
                        $scope.files.push(file);
    
                        document.location.reload();
                    } else {
                        pushNotifications.push('danger', 'close', 'Something went wrong', 4000);
                    }
                },
                function(data) {
                    // Handle error here
                    let errorMsg = '';

                    if(data.data && typeof data.data.errors != 'undefined'){
                        let errors = data.data.errors;

                        for(let error in errors){
                            errorMsg += errors[error] + '\n';
                        }
                    } else {
                        errorMsg = 'Something went wrong';
                    }
    
                    pushNotifications.push('danger', 'close', errorMsg, 4000);
                });   
            }
        });

        $element[0].appendChild(input);

        input.click();
    };
};

module.exports = () => {
    return {
        scope: {
            'change': '&',
            'files': '=?bind',
            'type': '@',
        },
        restrict: "EA",
        replace: true,
        controller: [
            '$scope',
            '$element',
            'FileService',
            'ModalService',
            FilesSelectorDirective
        ],
        templateUrl: '/assets/panel/tpl/directives/file-selector.html'
    };
};