let ModalMediaEditComponent = function(
    MediaService,
    LanguageService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.languages;
    $ctrl.language = 'nl';

    $ctrl.$onInit = () => {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        $ctrl.form = FormBuilderService.build({
            translations: $ctrl.modal.scope.media.translations || {}
        }, (form) => {
            MediaService.update(
                $ctrl.modal.scope.media.uid, form.values
            ).then(() => {
                $ctrl.close();
            });
        });
    };

    $ctrl.$onDestroy = function() { };
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'MediaService',
        'LanguageService',
        'FormBuilderService',
        ModalMediaEditComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-media-edit.html';
    }
};