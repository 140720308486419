let SpeakerEditComponent = function(
    SpeakerService,
    LanguageService,
    FormBuilderService,
    $timeout,
) {
    let $ctrl = this;

    $ctrl.speakers;
    $ctrl.speaker;
    $ctrl.languages;
    $ctrl.language = 'nl';

    let loadResources = () => {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        if ($ctrl.speakerId) {
            return SpeakerService.read($ctrl.speakerId).then((res) => {
                $ctrl.speaker = res.data.data;
                $ctrl.loadSpeaker($ctrl.speaker);
            });
        }

        $ctrl.speaker = false;

        $ctrl.loadSpeaker({});
    };

    $ctrl.submit = () => {
        
    }

    $ctrl.loadSpeaker = function(speaker) {
        let values = SpeakerService.apiResourceToForm(speaker);

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let promise;

            if ($ctrl.speaker) {
                promise = SpeakerService.update(
                    $ctrl.speaker.id,
                    form.values
                )
            } else {
                promise = SpeakerService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data) {
                    pushNotifications.push('success', 'check', 'Saved', 4000);

                    $timeout(function() {
                        document.location = res.data.data.url.admin_list;
                    }, 1000);
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();
            });
        });

        if (!Array.isArray($ctrl.blocks)) {
            $ctrl.blocks = [];
        }
    }

    $ctrl.$onInit = function() {
        $ctrl.speakers = false;
        $ctrl.speaker = false;
        
        loadResources();
    };
};

module.exports = {
    bindings: {
        'speakerId': '<'
    },
    controller: [
        'SpeakerService',
        'LanguageService',
        'FormBuilderService',
        '$timeout',
        SpeakerEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/speaker-edit.html'
};