let ReviewService = function(ApiRequest) {
    let uriPrefix = '/reviews';

    return new (function() {
        this.list = function(type) {
            return ApiRequest.get(uriPrefix, {
                type: type
            });
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(formData) {
            return JSON.parse(JSON.stringify(formData));
        };

        this.states = () => [{
            id: 'pending',
            name: 'Pending',
        }, {
            id: 'approved',
            name: 'Approved',
        }, {
            id: 'rejected',
            name: 'Rejected',
        }];

        this.apiResourceToForm = function(apiResource) {
            return {
                state: apiResource.state,

                name: apiResource.name,
                email: apiResource.email,
                website: apiResource.website,
                commentary: apiResource.commentary,
                user_function: apiResource.user_function,
                stars: apiResource.stars,
                media_id: apiResource.image ? apiResource.image.id : null,
            };
        };
    });
};

module.exports = [
    'ApiRequest',
    ReviewService
];