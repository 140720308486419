let ModalBlockComponent = function(
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.selectedCategoryIndex = 0;

        $ctrl.form = FormBuilderService.build({
            group_types: $ctrl.modal.scope.groupTypes
        }, (form) => {
            form.values.sel_block_types = [];
            
            form.values.group_types.forEach((category) => {
                category.group_blocks.forEach((blockType) => {
                    if(blockType.active){
                        form.values.sel_block_types.push(blockType);
                    }
                });
            });

            $ctrl.modal.scope.success(form.values);

            $ctrl.selectCategory(0);

            $ctrl.close();
        });

        $ctrl.selectedCategory = $ctrl.form.values.group_types[$ctrl.selectedCategoryIndex];
    };

    $ctrl.selectCategory = function(categoryIndex) {
        $ctrl.selectedCategoryIndex = categoryIndex;

        $ctrl.selectedCategory = $ctrl.form.values.group_types[$ctrl.selectedCategoryIndex];
    };

    $ctrl.$onDestroy = function() { };
};

module.exports = {
    bindings: {
        close: '=',
        modal: '=',
        groupTypes: '='
    },
    controller: [
        'FormBuilderService',
        ModalBlockComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-block-add.html';
    }
};