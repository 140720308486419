let fadeIn = function($scope, $element, attrs) {
    $element.addClass("ng-hide-remove");
    $element.on('load', function() {
        $element.addClass("ng-hide-add");
    });
};

module.exports = () => {
    return {
        restrict: "A",
        link: fadeIn
    };
};