let TranslationEditComponent = function(
    TranslationService,
    LanguageService,
    FormBuilderService,
    $timeout,
) {
    let $ctrl = this;

    $ctrl.translations;
    $ctrl.translation;
    $ctrl.languages;
    $ctrl.language = 'nl';

    let loadResources = () => {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        if ($ctrl.translationId) {
            return TranslationService.read($ctrl.translationId).then((res) => {
                $ctrl.translation = res.data.data;
                $ctrl.loadTranslation($ctrl.translation);
            });
        }

        $ctrl.translation = false;

        $ctrl.loadTranslation({});
    };

    $ctrl.submit = () => {
        
    }

    $ctrl.loadTranslation = function(translation) {
        let values = TranslationService.apiResourceToForm(translation);

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let promise;

            if ($ctrl.translation) {
                promise = TranslationService.update(
                    $ctrl.translation.id,
                    form.values
                )
            } else {
                promise = TranslationService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data) {
                    pushNotifications.push('success', 'check', 'Saved', 4000);

                    $timeout(function() {
                        document.location = res.data.data.url.admin_list;
                    }, 1000);
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();
            });
        });

        if (!Array.isArray($ctrl.blocks)) {
            $ctrl.blocks = [];
        }
    }

    $ctrl.$onInit = function() {
        $ctrl.translations = false;
        $ctrl.translation = false;
        
        loadResources();
    };
};

module.exports = {
    bindings: {
        'translationId': '<'
    },
    controller: [
        'TranslationService',
        'LanguageService',
        'FormBuilderService',
        '$timeout',
        TranslationEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/translation-edit.html'
};