let BlockGroupsService = function(ApiRequest) {
    let uriPrefix = '/block-groups';

    return new(function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }
    });
};

module.exports = [
    'ApiRequest',
    BlockGroupsService
];