let PhotoSelectorDirective = function(
    $timeout,
    $scope,
    $element,
    ImageConvertorService,
    MediaService,
    ModalService,
) {
    let input = false;

    $scope.uploading = false;

    $scope.showImportFromLibrary = $scope.type == 'block_photo';

    $scope.mediaEdit = (media) => {
        ModalService.open('mediaEdit', {
            media: media
        });
    };

    $scope.mediaDelete = (e) => {
        e && e.preventDefault() && e.stopPropagation();
        
        $scope.media = false;
        $scope.thumbnail = false;

        if (typeof $scope.deletePhoto == 'function') {
            $scope.deletePhoto();
        }
    };

    $scope.selectFile = (e) => {
        e && e.preventDefault() && e.stopPropagation();

        if (input && input.remove) {
            input.remove();
        }

        input = document.createElement('input');
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.style.display = 'none';

        input.addEventListener('change', function(e) {
            let file = e.target.files[0];

            ImageConvertorService.instance(file).then((converter) => {
                $timeout(() => {
                    $scope.thumbnail = converter.resize(200, 200);
                }, 0);
            });

            $scope.uploading  = true;

            MediaService.store($scope.type, file).then(res => {
                $scope.uploading = false;
                $scope.media = res.data.data;
                $scope.selectPhoto({
                    media: $scope.media
                });
            });

            /* ModalService.open('photoUploader', {
                type: $scope.type,
                getFile: () => {
                    return e.target.files[0];
                },
                submit: (file) => {
                    ImageConvertorService.instance(file).then((converter) => {
                        $timeout(() => {
                            $scope.thumbnail = converter.resize(100, 100);
                        }, 0);
                    });

                    $scope.selectPhoto({
                        file: file
                    });
                }
            }); */
        });

        $element[0].appendChild(input);

        input.click();
    };

    $scope.addFromLibrary = function() {
        ModalService.open('loadLibrary', {
            multiple: false,
            success: (data) => {
                //Add image from library

                $scope.media = data.sel_medias;

                $scope.selectPhoto({
                    media: $scope.media
                });
            }
        });
    };
};

module.exports = () => {
    return {
        scope: {
            'selectPhoto': '&',
            'deletePhoto': '&',
            'media': '=',
            'type': '@',
        },
        restrict: "EA",
        replace: true,
        controller: [
            '$timeout',
            '$scope',
            '$element',
            'ImageConvertorService',
            'MediaService',
            'ModalService',
            PhotoSelectorDirective
        ],
        templateUrl: '/assets/panel/tpl/directives/photo-selector.html'
    };
};