require('./angular-1/angular-app');

// dev-pages
// 
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(function() {
    if ($(".dev-pages").length > 0) {
        $(".dev-pages .show").bind('click', function(e) {
            $(this).parent().toggleClass('active');
            return false;
        });
    }
});

(function($) {
    $.prototype.checkboxSelector = function() {
        var self = $(this);
        var boxes = [];

        if (self.length === 0)
            return;

        var boxItem = function(box) {
            var _self = this;

            var input = box.find('[type="checkbox"]')[0];

            _self.init = function() {
                _self.bind();

                if (input.checked)
                    box.addClass('checked');
            };

            _self.bind = function() {
                box.unbind('click').bind('click', function() {
                    box.toggleClass('checked');
                    input.checked = box.hasClass('checked');
                });
            };

            _self.init();
        };

        self.each(function(_ai, _av) {
            boxes.push(new boxItem($(_av)));
        });
    };

    $.prototype.radioSelector = function() {
        var self = $(this);
        var radios = [];

        if (self.length === 0)
            return;

        var radioItem = function(radio) {
            var _self = this;

            var input = radio.find('[type="radio"]')[0];

            var sib = $('input[name="' + input.name + '"]');

            _self.init = function() {
                _self.bind();

                if (input.checked)
                    radio.addClass('checked');
            };

            _self.bind = function() {
                radio.unbind('click').bind('click', function() {
                    sib.removeClass('checked');
                    $(this).addClass('checked');

                    input.checked = radio.hasClass('checked');

                    sib.each(function(_ai, _av) {
                        if (_av.checked) {
                            $(_av).closest('[radio-selector]').addClass('checked');
                        } else {
                            $(_av).closest('[radio-selector]').removeClass('checked');
                        }
                    });
                });
            };

            _self.init();
        }

        self.each(function(_ai, _av) {
            radios.push(new radioItem($(_av)));
        });
    };
})(jQuery);

$(function() {
    $('[checkbox-selector]').checkboxSelector();
    $('[radio-selector]').radioSelector();
});

$(function() {
    $('[user-selector] .short').unbind('click').bind('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!$(this).parent().hasClass('active')) {
            $(this).parent().addClass('active');

            $('body').unbind('click.user-menu').bind('click.user-menu', function(e) {
                $('[user-selector]').removeClass('active');
                $('body').unbind('click.user-menu');
            });
        }
    });
});

$(function() {
    $('.nano').nanoScroller();
});


(function($) {
    $.prototype.mediaUploader = function() {
        var self = this;
        var selectors = [];

        var header = $('meta[name="csrf-token"]').attr('content');
        var types = ['image/png', 'image/jpg', 'image/jpeg'];

        if (self.length === 0)
            return;

        var mediaUploaderItem = function($node) {
            var _self = this;

            var trigger = $node.find('[trigger]');
            var preview = $node.find('[preview]');

            var image;
            var file_input;
            var input_name = 'image';
            var std = preview.attr('src');

            var _type = $node.attr('type');

            _type = _type ? _type : 'avatar';

            _self.init = function() {
                if ($node.attr('name'))
                    input_name = $node.attr('name');

                file_input = $('<input name="avatar" type="file" />');
                image = $('<input name="' + input_name + '" type="text" class="hidden" />');

                $node.append(image);

                if ($node.attr('old-value'))
                    _self.loadData($node.attr('old-value'));

                _self.bind();
            };

            _self.bind = function() {
                trigger.unbind('click').bind('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    file_input.click();
                });

                file_input.unbind('change').bind('change', function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    preview.attr('src', std);
                    image.val('');

                    if (types.indexOf(e.target.files[0].type) != -1)
                        _self.doUpload(e.target.files[0]);

                    delete e.target.files;
                });
            };

            _self.doUpload = function(file) {
                var request = new XMLHttpRequest();
                var form_data = new FormData();

                form_data.append('image', file);
                form_data.append('mediable_type', $node.attr('mediable-type'));
                form_data.append('mediable_id', $node.attr('mediable-id'));
                form_data.append('type', $node.attr('type'));

                request.open('POST', '/media/single-upload/' + _type);
                request.setRequestHeader('X-CSRF-TOKEN', header);
                request.setRequestHeader('Accept', 'application/json');

                request.onload = function(data) {
                    data = JSON.parse(data.target.response);

                    image.val(data.mediaId);
                    preview.attr('src', data.dataUrl);
                }

                request.send(form_data);
            }

            _self.loadData = function(id) {
                var request = new XMLHttpRequest();
                var form_data = new FormData();

                form_data.append('id', id);

                request.open('POST', '/media/single-media-url-data');
                request.setRequestHeader('X-CSRF-TOKEN', header);

                request.onload = function(data) {
                    data = JSON.parse(data.target.response);

                    image.val(id);
                    preview.attr('src', data.dataUrl);
                }

                request.send(form_data);

            }

            _self.init();
        }

        $.each(self, function(_ai, _av) {
            selectors.push(new mediaUploaderItem($(_av)));
        });
    };
})(jQuery);

$.prototype.radioInput = function() {
    var self = this;
    var _lists = [];

    if (self.length < 1)
        return;

    var update = function() {
        for (var i = _lists.length - 1; i >= 0; i--) {
            var _input = _lists[i].find('input[type="radio"]');

            if (_input[0].checked) {
                _lists[i].attr('checked', true);
            } else {
                _lists[i].removeAttr('checked');
            }

        };
    }

    var _checkbox = function(_self) {
        var input = _self.find('input[type="radio"]');

        _self.unbind('click').bind('click', function(e) {
            if (e.target == _self[0]) {
                input.click();
                update();
            }
        });

        return _self;
    }

    for (var i = self.length - 1; i >= 0; i--) {
        _lists.push(_checkbox($(self[i])));
    };

    update();
};


$(function() {
    $('[media-uploader]').mediaUploader();
    $('label.radio').radioInput();
});


$(function() {
    $('[show-agenda-bar]').unbind('click').bind('click', function() {
        $('.block-journal-bar').toggleClass('open');
        return false;
    });
});

// jquery param - deserializator
(function($) {
    $.deserialize = function(str, options) {
        var pairs = str.split(/&amp;|&/i),
            h = {},
            options = options || {};
        for (var i = 0; i < pairs.length; i++) {
            var kv = pairs[i].split('=');
            kv[0] = decodeURIComponent(kv[0]);
            if (!options.except || options.except.indexOf(kv[0]) == -1) {
                if ((/^\w+\[\w+\]$/).test(kv[0])) {
                    var matches = kv[0].match(/^(\w+)\[(\w+)\]$/);
                    if (typeof h[matches[1]] === 'undefined') {
                        h[matches[1]] = {};
                    }
                    h[matches[1]][matches[2]] = decodeURIComponent(kv[1]);
                } else {
                    h[kv[0]] = decodeURIComponent(kv[1]);
                }
            }
        }
        return h;
    };

    $.fn.deserialize = function(options) {
        return $.deserialize($(this).serialize(), options);
    };
})(jQuery);


$.prototype.embedInfo = function(_args) {
    var list = this;
    var coll = [];

    if (list.length == 0)
        return;

    var embedInfo = function(_self) {
        var short_url = _self.find('[short-url]');
        var embed_code = _self.find('[embed-code]');
        var js_api = _self.find('[js-api]');

        var tabs = _self.find('.header .item .inner');

        var url_prev = _self.find('[url-preview]');
        var url_prev_text = url_prev.attr('url-preview');

        var url_token_input = short_url.find('input[name="token"]');
        var url_skin_input = short_url.find('select[name="skin"]');

        var embed_prev = _self.find('[embed-preview]');

        var embed_width_input = embed_code.find('input[name="width"]');
        var embed_height_input = embed_code.find('input[name="height"]');
        var embed_skin_input = embed_code.find('select[name="theme_key"]');

        var active;

        var updatePreviews = function() {
            var url_text;
            url_text = url_prev_text.replace('[token]', url_token_input.val());
            url_prev.text(url_text);

            embed_prev.each(function(key) {
                var embedPreview = $(this);
                var embedText;
                var embedPrevText = embedPreview.attr('embed-preview');

                // var embed_param = '?skin=' + embed_skin_input.val();
                var embedAttr = 'width="' + embed_width_input.val() + '" ';

                embedAttr += 'height="' + embed_height_input.val() + '" ';
                embedAttr += 'style="max-width: 100%" ';

                embedText = embedPrevText.replace('[skin]', embed_skin_input.val());
                embedText = embedText.replace('[attr]', embedAttr);
                embedPreview.text(embedText);
            });
        };

        var hideAll = function() {
            short_url.addClass('hidden');
            embed_code.addClass('hidden');
            js_api.addClass('hidden');
        };

        var bind = function() {
            tabs.unbind('click').bind('click', function(e) {
                var _this = $(this);
                var _target = _this.attr('data-target');

                if (_this.hasClass('active')) {
                    _this.removeClass('active');
                    _self.find(_target).addClass('hidden');

                    document.location.hash = '';
                } else {
                    hideAll();
                    document.location.hash = _target;

                    tabs.removeClass('active');
                    _this.addClass('active');
                    _self.find('[' + _target + ']').removeClass('hidden');
                }

            });

            // url details update
            url_token_input.unbind('change paste keydown keyup')
                .bind('change paste keydown keyup', function() {
                    updatePreviews();
                });

            // embed details update
            embed_width_input.unbind('change paste keydown keyup')
                .bind('change paste keydown keyup', function() {
                    updatePreviews();
                });

            embed_height_input.unbind('change paste keydown keyup')
                .bind('change paste keydown keyup', function() {
                    updatePreviews();
                });

            embed_skin_input.unbind('change paste keydown keyup')
                .bind('change paste keydown keyup', function() {
                    updatePreviews();
                });

            $(window).unbind('hashchange').bind('hashchange', function(e) {
                hashchange();
            });
        };

        var hashchange = function() {
            var hash = document.location.hash.slice(1);
            var tab = _self.find('[data-target="' + hash + '"]');
            if (tab.length > 0 && !tab.hasClass('active')) {
                tab.click();
            }
        };

        var init = function() {
            hideAll();
            bind();
            hashchange();
            updatePreviews();
        };

        init();
    };

    for (var i = list.length - 1; i >= 0; i--) {
        coll.push(new embedInfo($(list[i])));
    }
};

$(function() {
    $('[embed-info]').embedInfo();
});

$.prototype.agenda = function() {
    var self = this;
    var coll = [];

    if (self.length == 0)
        return;

    var agenda = function(_self) {
        var date = _self.attr('date');
        var type = _self.attr('type');

        var _pag = _self.find('[pagination]');

        var _types = _self.find('[types]');
        var _title = _self.find('[title]');

        var _table = _self.find('[table]');
        var _details = _self.find('[details]');
        var syncInProgress = false;

        this.bind = function() {
            var __self = this;

            _types.unbind('change').bind('change', function() {
                type = _types.val();

                __self.sync(type, moment().format('YYYY-MM-DD'));

                return false;
            });

            _self.find('[agenda-month]').unbind('click').bind('click', function() {
                __self.sync('month', $(this).attr('agenda-month'), true);
                return false;
            });

            _self.find('[agenda-week]').unbind('click').bind('click', function() {
                __self.sync('week', $(this).attr('agenda-week'), true);
                return false;
            });

            _self.find('[agenda-date]').unbind('click').bind('click', function() {
                if (!$(this).hasClass('disabled'))
                    __self.sync('date', $(this).attr('agenda-date'), true);
                return false;
            });

            _self.find('[agenda-details]').unbind('click').bind('click', function() {
                var val = $(this).attr('agenda-details').split('|');
                __self.view(val[0], val[1]);
                return false;
            });

            _self.find('[app-status]').unbind('click').bind('click', function(e) {
                __self.app_status(
                    $(this).attr('app-status'),
                    $(this).attr('app-id'));

                return false;
            });

            _self.find('[close]').unbind('click').bind('click', function() {
                _details.find('.block-journal-bar').removeClass('open');
                return false;
            });

            $(window).unbind('hashchange').bind('hashchange', function() {
                __self.hashchange();
            });
        }

        this.hashchange = function() {
            var hash = $.deserialize(document.location.hash.slice(1));

            if (typeof hash == 'object') {
                if (typeof hash.type == 'string')
                    type = hash.type;

                if (typeof hash.date == 'string')
                    date = hash.date;
            }

            this.sync(type, date, true, true);
        };

        this.sync = function(_type, _date, _scroll, _dont_hash) {
            var __self = this;

            if (syncInProgress) {
                return this;
            }

            syncInProgress = true;
            type = _type;
            date = _date;

            _types.val(type);

            $.ajax({
                method: 'get',
                url: '/api/panel/agenda/sync',
                data: {
                    type: type,
                    date: date
                },
                success: function(data) {
                    if (_scroll) {
                        $('html, body').scrollTop(0);
                    }

                    var _view = $(data.view);

                    _pag.html(_view.find('[pagination]').html());

                    _view.find('[pagination]').remove();

                    _title.text(data.title);

                    _table.html(_view.html());

                    __self.bind();

                    if (!_dont_hash) {
                        document.location.hash = $.param({
                            type: type,
                            date: date
                        });
                    }

                    setTimeout(function() {
                        syncInProgress = false;
                    }, 100);
                }
            });
        }

        this.view = function(_type, _date) {
            var __self = this;

            if (_details.find('.block-journal-bar.wait').length > 0)
                return false;

            if (_details.find('.block-journal-bar.open').length > 0) {
                _details.find('.block-journal-bar.open')
                    .addClass('wait')
                    .removeClass('open');

                setTimeout(function() {
                    _details.find('.block-journal-bar').remove();
                    __self.view(_type, _date);
                }, 400);

                return;
            }

            $.ajax({
                method: 'get',
                url: '/api/panel/agenda/find',
                data: {
                    type: _type,
                    date: _date
                },
                success: function(data) {
                    var _view = $(data.view);

                    _details.html(_view.html());

                    setTimeout(function() {
                        _details.find('.block-journal-bar').addClass('open');
                    }, 100);

                    __self.bind();
                }
            });
        }

        this.app_status = function(_url, _target) {
            var __self = this;

            $.ajax({
                method: 'get',
                url: _url
            }).then(function(data) {
                console.log('data', data);
                _details.find('[app-item=' + _target + ']').remove();

                if (_details.find('[app-item]').length < 1)
                    _details.find('.block-journal-bar').removeClass('open');

                __self.sync(type, date);
            }, console.log);
        }

        this.init = function() {
            type = _types.val();

            this.hashchange();
        }

        this.init();
    }

    for (var i = self.length - 1; i >= 0; i--) {
        coll.push(new agenda($(self[i])));
    };
};

window.pushNotifications = new(function($node) {
    $node = $('[push-notifications]');

    var self = this;
    var notes = $node.find('.notification');
    var template = $node.find('.template');
    var shownNotes = [];
    var maxCount = 4;

    self.bind = function($note) {
        if (!$note.hasClass('shown')) {
            $note.addClass('shown');
        }

        var note = {
            note: $note
        };

        note.timeout = setTimeout(function() {
            self.deleteNotification(note);
        }, parseInt(note.note.attr('expire')) * 1);

        shownNotes.push(note);

        note.note.find('.close').unbind('click').bind('click', function() {
            self.deleteNotification(note);
        });

        if (shownNotes.length > maxCount) {
            var _note = shownNotes[0];

            self.deleteNotification(_note);
        }
    };

    self.deleteNotification = function(note) {
        clearTimeout(note.timeout);
        note.note.removeClass('shown');
        shownNotes.splice(shownNotes.indexOf(note), 1);

        setTimeout(function() {
            note.note.remove();
        }, 450);
    };

    self.init = function() {
        notes.each(function(index, note) {
            self.bind($(note));
        });
    };

    self.push = function(type, icon, text, timeout) {
        var notification = template.clone();

        notification.removeClass('template');
        notification.addClass('notification');
        notification.addClass('notification-' + type);

        notification.removeAttr('hidden');
        notification.attr('expire', timeout);
        notification.find('.text').text(text);
        notification.find('.icon').addClass('mdi mdi-' + icon);

        $node.find('.inner').prepend(notification);

        setTimeout(function() {
            self.bind(notification);
        }, 100);
    };

    self.init();
})();

$('[test-notifications]').unbind('click').bind('click', function() {
    var types = ['success', 'danger', 'warning'];
    var icons = ['check-circle-outline', 'close-circle-outline', 'information-outline'];
    var texts = [
        'Lorem ipsum dolor sit amet.',
        'Lorem ipsum dolor sit amet, consectetur.',
        'Lorem ipsum dolor sit amet, consectetur adipisicing.'
    ];

    var type = _.random(0, types.length - 1);

    pushNotifications.push(
        types[type],
        icons[type],
        texts[_.random(0, texts.length - 1)],
        5000
    );
});

$.prototype.radioTabs = function(conf) {
    conf = conf || {};

    conf.tab_selector = conf.tab_selector || 'radio-tabs-bind';
    conf.pane_selector = conf.pane_selector || 'radio-tabs-pane';

    var RadioTabs = function($root) {
        var tabs = {};
        var panes = {};

        var $tabs = $root.find('[' + conf.tab_selector + ']');
        var $panes = $root.find('[' + conf.pane_selector + ']');

        for (var i = $panes.length - 1; i >= 0; i--) {
            $($panes[i]).hide();
        }

        $tabs.unbind('click').bind('click', function(e) {
            var $input = $(e.target);
            var value = $input.attr(conf.tab_selector);

            if ($input.attr('type') == 'radio') {
                $panes.filter('[' + conf.pane_selector + '^="' + value.split('|')[0] + '|"]').hide();
                $panes.filter('[' + conf.pane_selector + '="' + value + '"]').show();
            } else {
                var $inputs = $root.find('[' + conf.tab_selector + '^="' + value.split('|')[0] + '|"]');

                $inputs.each(function() {
                    var $input = $(this);
                    $pane = $root.find('[' + conf.pane_selector + '="' + $input.attr(conf.tab_selector) + '"]');

                    if ($input.is(':checked')) {
                        $pane.show();
                    } else {
                        $pane.hide();
                    }
                });
            }
        });

        for (var i = $tabs.length - 1; i >= 0; i--) {
            if ($tabs[i].type == 'radio' && $($tabs[i]).is(':checked')) {
                $($tabs[i]).click();
            } else {
                $($tabs[i]).click();
                $($tabs[i]).click();
            }
        }
    }

    for (var i = this.length - 1; i >= 0; i--) {
        new RadioTabs($(this[i]));
    }
};

$('body').radioTabs();

$(function() {
    $('[agenda]').agenda();
});


$('[toggle-sidebar]').unbind('click').bind('click', function(e) {
    e && e.preventDefault() && e.stopPropagation();

    var $body = $('body');

    if ($body.hasClass('sidebar-opened')) {
        $body.removeClass('sidebar-opened');
    } else {
        $body.addClass('sidebar-opened');
    }
});

$('[toggle-navbar]').unbind('click').bind('click', function(e) {
    e && e.preventDefault() && e.stopPropagation();

    var $body = $('body');

    if ($body.hasClass('navbar-opened')) {
        $body.removeClass('navbar-opened');
    } else {
        $body.addClass('navbar-opened');
    }
});

$('.block-aside .menu .menu-item .submenu-toggle').unbind().bind('click', function(e) {
    e && e.preventDefault() & e.stopPropagation();

    $(this).parent().closest('.menu-item').toggleClass('open');
    $('.block-aside .menu').nanoScroller();
});

$('.block-layout .menu-submenu .menu-item.active').each(function() {
    var target_parent = $(this).parent().closest('.menu-item');

    if (!target_parent.hasClass('open'))
        target_parent.find('.submenu-toggle').click();
});

$.prototype.languageSwitcher = function(args) {
    var languageSwitcher = function($toggle) {
        var toggleVisibility = function($input) {
            $('[language-option]').addClass('hidden');
            console.log($input);
            $('[language-option=' + $input[0].value + ']').removeClass('hidden');
        };

        $toggle.unbind('change').bind('change', function() {
            toggleVisibility($(this));
        });

        toggleVisibility($toggle);
    };

    for (var i = this.length - 1; i >= 0; i--) {
        new languageSwitcher($(this[i]));
    }
};

$('[language-switcher]').languageSwitcher();

$('[name="per_page"]').change(function(){
    document.location.search = '?per_page=' + $(this).val(); 
});

/*var hasChosenStar = false,
    $starsInput = $('form [name="stars"]'),
    starsSelector = '.stars-block .star';

if($starsInput.length && $starsInput.val()){
    setActiveStars($starsInput.val());
    hasChosenStar = true;
}

function setActiveStars(lastIndex) {
    $(starsSelector).each(function() {
        var starImage = '/assets/img/' + ($(this).data('index') <= lastIndex ? 
            'star-active.png' : 'star-normal.png');

        $(this).attr('src', starImage);
    });
}

$(document).on('mouseover', starsSelector, function(){
    setActiveStars($(this).data('index'));
});

$(document).on('mouseout', starsSelector, function(){
    setActiveStars($starsInput.val());
});

$(document).on('click', starsSelector, function(){
    setActiveStars($(this).data('index'));

    $starsInput.val($(this).data('index'));

    hasChosenStar = true;
});*/