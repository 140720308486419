let BaseController = function(
    $rootScope,
    $scope,
    $state,
    appConfigs
) {
    $rootScope.$state = $state;
    $rootScope.appConfigs = appConfigs;
    $scope.appConfigs = appConfigs;
};

module.exports = [
    '$rootScope',
    '$scope',
    '$state',
    'appConfigs',
    BaseController
];