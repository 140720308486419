let PaginationComponent = function(
    PaginationService
) {
    let $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.service.list({
            per_page: $ctrl.perPage,
            page: 1
        })
        .then(resp => {
            $ctrl.data = resp.data;

            $ctrl.itemCount = $ctrl.data.meta.total;
        
            $ctrl.pagination = PaginationService.getPagination(
                $ctrl.itemCount, 1, $ctrl.perPage
            );
        });
    };

    $ctrl.$onDestroy = function() { };

    $ctrl.moveToPage = function($event, pageNr) {
        $ctrl.service.list({
            per_page: $ctrl.perPage,
            page: pageNr
        })
        .then(resp => {
            $ctrl.data = resp.data;
        });

        $ctrl.pagination = PaginationService.getPagination(
            $ctrl.itemCount, pageNr, $ctrl.perPage
        );

        $event.preventDefault();
        $event.stopPropagation();
    }
};

module.exports = {
    bindings: {
        itemCount: '@',
        perPage: '@',
        service: '=',
        data: '='
    },
    controller: [
        'PaginationService',
        PaginationComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/directives/pagination.html';
    }
};