let SpeakerService = function(ApiRequest) {
    let uriPrefix = '/speakers';

    return new (function() {
        this.list = function(type) {
            return ApiRequest.get(uriPrefix, {
                type: type
            });
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(formData) {
            let values = JSON.parse(JSON.stringify(formData));

            values.translations = values.translations || [];
            Object.assign(values, values.translations.nl || {});

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            return {
                translations: apiResource.translations,
                title: apiResource.title,
                subtitle: apiResource.subtitle,
                description: apiResource.description,
            };
        };
    });
};

module.exports = [
    'ApiRequest',
    SpeakerService
];