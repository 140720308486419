module.exports = ['ModalRouteProvider', function(ModalRouteProvider) {
    ModalRouteProvider.modal('photoUploader', {
        component: 'modalPhotoUploaderComponent'
    });

    ModalRouteProvider.modal('fundTopUp', {
        component: 'modalFundTopUpComponent'
    });

    ModalRouteProvider.modal('employeeEdit', {
        component: 'modalEmployeeEditComponent'
    });

    ModalRouteProvider.modal('mediaEdit', {
        component: 'modalMediaEditComponent'
    });

    ModalRouteProvider.modal('fileEdit', {
        component: 'modalFileEditComponent'
    });

    ModalRouteProvider.modal('modalNotification', {
        component: 'modalNotificationComponent'
    });

    ModalRouteProvider.modal('modalPinCode', {
        component: 'modalPinCodeComponent'
    });

    ModalRouteProvider.modal('modalAuth', {
        component: 'modalAuthComponent'
    });

    ModalRouteProvider.modal('markdownCustomLink', {
        component: 'modalMarkdownCustomLinkComponent'
    });

    ModalRouteProvider.modal('markdownPageLink', {
        component: 'modalMarkdownPageLinkComponent'
    });

    ModalRouteProvider.modal('blockAdd', {
        component: 'modalBlockComponent'
    });

    ModalRouteProvider.modal('loadLibrary', {
        component: 'modalLoadLibraryComponent'
    });

    ModalRouteProvider.modal('prompt', {
        component: 'modalPromptComponent'
    });
}];
