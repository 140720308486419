let PageEditComponent = function(
    PageService,
    MenuService,
    LanguageService
) {
    let $ctrl = this;

    $ctrl.menu;
    $ctrl.menuItem;
    $ctrl.menuItemEdit;
    $ctrl.menus;
    $ctrl.pages;
    $ctrl.languages;
    $ctrl.language = 'nl';

    let loadResources = () => {
        MenuService.list().then((res) => {
            $ctrl.menus = res.data.data;

            if ($ctrl.menus.length > 0) {
                $ctrl.menu = $ctrl.menus[0];
            }
        });

        PageService.list().then((res) => {
            $ctrl.pages = res.data.data;

            $ctrl.pages.unshift({
                id: null,
                title: "Select page"
            });
        });

        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });
    };

    $ctrl.selectMenuItem = (e, menu_item) => {
        e.preventDefault() & e.stopPropagation();

        if ($ctrl.menu.depth == 1) {
            return;
        }

        if (!$ctrl.menuItem || $ctrl.menuItem.id != menu_item.id) {
            $ctrl.menuItem = menu_item;
        } else {
            $ctrl.menuItem = false;
        }
    };

    $ctrl.deleteMenuItem = (e, parent, menu_item) => {
        if (Array.isArray(parent.menu_items)) {
            let index = parent.menu_items.indexOf(menu_item);

            if (index != -1) {
                parent.menu_items.splice(index, 1);
            }
        }
    };

    $ctrl.changePage = (item) => {
        item.page = $ctrl.pages.filter((page) => page.id == item.page_id)[0];
    };

    $ctrl.editItemSubmit = () => {
        $ctrl.menuItemEdit = false;
    };

    $ctrl.addPage = (parent) => {
        parent.menu_items.push({
            translations: {},
            menu_items: [],
            page_id: null,
        });
    }

    $ctrl.selectPhotoMenuItemSingle = (menuItem, media) => {
        menuItem.media_id = media.id;
    };

    $ctrl.deletePhotoMenuItemSingle = (menuItem) => {
        delete menuItem.media;
        menuItem.media_id = null;
    };

    $ctrl.selectMenuItemEdit = (e, menuItem) => {
        e.preventDefault() & e.stopPropagation();

        if ($ctrl.menuItemEdit != menuItem) {
            $ctrl.menuItemEdit = menuItem;
        } else {
            $ctrl.menuItemEdit = false;
        }
    };

    $ctrl.onChangeMenu = () => {
        $ctrl.menuItem = false;
        $ctrl.menuItemEdit = false;
    }

    $ctrl.submit = () => {
        let recursiveItems = (items) => {
            return items.map(item => {
                let itemData = {
                    id: item.id,
                    page_id: item.page_id,
                    media_id: item.media_id,
                    parent_id: item.parent_id,
                    menu_class: item.menu_class,
                    translations: item.translations,
                };

                if (Array.isArray(item.menu_items)) {
                    itemData.menu_items = recursiveItems(item.menu_items);
                } else {
                    itemData.menu_items = [];
                }

                return itemData;
            });
        };

        let menuItems = $ctrl.menus.map((menu) => {
            return {
                id: menu.id,
                key: menu.key,
                name: menu.name,
                menu_items: recursiveItems(menu.menu_items),
            };
        });

        let count = menuItems.length;

        menuItems.forEach(menuItem => {
            let _menuItem = menuItem;
            
            MenuService.update(_menuItem.id, _menuItem).then(() => {
                if (--count == 0) {
                    pushNotifications.push('success', 'check', 'Saved', 4000);
                }
            }, () => {
                pushNotifications.push('danger', 'close', 'Could not save "' + _menuItem.name + '" menu.', 4000);
            })
        });
    }

    // sortable settings for bots, options and controls
    $ctrl.sortable = {
        option: {
            sort: true,
            animation: 150,
            handle: '.handle-opt',
            draggable: '.item-drag-opt',
            group: 'options'
        },
        control: {
            sort: true,
            animation: 150,
            handle: '.handle-cont',
            draggable: '.item-drag-cont',
            group: 'controls',
            // fix for nested sortable
            // disable parent sortable
            me: function() {
                this.disabled = true;
            },
            ml: function() {
                this.disabled = false;
            }
        },
    };

    $ctrl.$onInit = function() {
        $ctrl.menu = false;
        $ctrl.menuItem = false;
        $ctrl.menuItemEdit = false;
        
        loadResources();
    };
};

module.exports = {
    controller: [
        'PageService',
        'MenuService',
        'LanguageService',
        PageEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/menu-edit.html'
};