let ReviewCategoryService = function(ApiRequest) {
    let uriPrefix = '/review-categories';

    return new (function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(formData) {
            let values = JSON.parse(JSON.stringify(formData));

            values.translations = values.translations || [];
            Object.assign(values, values.translations.nl || {});

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            return {
                translations: apiResource.translations,
                title: apiResource.title
            };
        };
    });
};

module.exports = [
    'ApiRequest',
    ReviewCategoryService
];