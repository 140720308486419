let InputCheckboxControlDirective = function($scope) {

    $scope.syncValues = function(){
        if(!$scope.ctrl) 
            return;
        
        let language = $scope.ctrl.language,
            values = {};

        values[language] = $scope.ngModel;
        $scope.block.values[$scope.blockTypeField] = values;

        $scope.ctrl.syncFieldValues($scope.block, $scope.blockTypeField);
    }
    
};

module.exports = () => {
    return {
        scope: {
            label: "@",
            name: "@",
            ngModel: '=',
            ctrl: '<',
            block: '<',
            blockTypeField: '@'
        },
        restrict: "EA",
        replace: true,
        controller: [
            '$scope',
            InputCheckboxControlDirective
        ],
        templateUrl: '/assets/panel/tpl/directives//input-checkbox-control.html' 
    };
};