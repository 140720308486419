let PageService = function(
    ApiRequest,
    LocalStorageService
) {
    let uriPrefix = '/pages';

    return new (function() {
        this.list = function(type) {
            return ApiRequest.get(uriPrefix, {
                type: type
            });
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(formData) {
            let values = JSON.parse(JSON.stringify(formData));

            values.translations = values.translations || [];
            Object.assign(values, values.translations.nl || {});

            return values;
        };

        this.states = () => [{
            id: 'active',
            name: 'Active',
        }, {
            id: 'pending',
            name: 'Pending',
        }];

        this.apiResourceToForm = function(apiResource) {
            return {
                translations: apiResource.translations,
                parent_id: (apiResource ? (apiResource.parent_id != null ? apiResource.parent_id + '' : null) : null),
                slug: apiResource.slug,
                type: apiResource.type,
                state: apiResource.state,

                title: apiResource.title,
                subtitle: apiResource.subtitle,
                interested: apiResource.interested,

                media_id: apiResource.featured_image ? apiResource.featured_image.id : null,

                featured: !!apiResource.featured,
                featured_order: !isNaN(parseInt(apiResource.featured_order)) ? parseInt(
                    apiResource.featured_order
                ) : 0,
                featured_title: apiResource.featured_title,
                featured_subtitle: apiResource.featured_subtitle,
                featured_description: apiResource.featured_description,
                featured_button: apiResource.featured_button,
                categories: apiResource.categories ? apiResource.categories.map((category) => category.id) : [],
                
                header_img_position: apiResource.header_img_position ? apiResource.header_img_position : 'middle', 
            };
        };

        this.setEditorLocale = function(id, locale) {
            LocalStorageService.setCollectionItem('page_locales', id, locale);
        };

        this.getEditorLocale = function(id, locale = 'nl') {
            return LocalStorageService.getCollectionItem('page_locales', id, locale);
        };
    });
};

module.exports = [
    'ApiRequest',
    'LocalStorageService',
    PageService
];