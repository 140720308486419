let PhotoSelectorDirective = function(
    $scope,
    $element,
    MediaService,
    ModalService,
) {
    let input = false;

    if (!$scope.medias) {
        $scope.medias = [];
    }

    $scope.mediaEdit = (media) => {
        ModalService.open('mediaEdit', {
            media: media
        });
    };

    $scope.mediaDelete = (media) => {
        if (!Array.isArray($scope.medias)) {
            $scope.medias = [];
        }

        let index = $scope.medias.indexOf(media);

        if (index != -1) {
            $scope.medias.splice(index, 1);

            $scope.change({
                media_ids: $scope.medias.map(media => media.id)
            });
        }
    };

    $scope.selectFile = (e) => {
        e && e.preventDefault() && e.stopPropagation();

        if (input && input.remove) {
            input.remove();
        }

        input = document.createElement('input');
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.setAttribute("multiple", "");
        input.style.display = 'none';

        input.addEventListener('change', function(e) {
            for (let index = 0; index < e.target.files.length; index++) {
                const file = e.target.files[index];

                MediaService.store($scope.type, file).then(res => {
                    let media = res.data.data;

                    $scope.medias.push(media);

                    $scope.change({
                        media_ids: $scope.medias.map(media => media.id)
                    });
                });   
            }
        });

        $element[0].appendChild(input);

        input.click();
    };

    $scope.addFromLibrary = function() {
        ModalService.open('loadLibrary', {
            multiple: true,
            success: (data) => {
                //Add image from library
                let addedImages = data.sel_medias;
                
                addedImages.forEach(media => {
                    $scope.medias.push(media);    
                });

                //Sync media ids
                $scope.change({
                    media_ids: $scope.medias.map(media => media.id)
                });
            }
        });
    };

    $scope.sortable = {
        sort: true,
        animation: 150,
        handle: '.sortable-image',
        chosenClass: 'sortable-chosen',
        ghostClass: 'sortable-ghost',
        group: 'gallery-images',
        me: function () {
            this.disabled = true;
        },
        ml: function () {
            this.disabled = false;
        },
        onStart: function ($event) {
            //angular.element($event.originalEvent.item).removeClass('use-hover');
            let imgElements = angular.element($event.originalEvent.target).find('.img');
            
            imgElements.removeClass('use-hover');
        },
        onEnd: function ($event) {
            let imgElements = angular.element($event.originalEvent.target).find('.img');
            
            imgElements.addClass('use-hover');
        },
        onSort: function($event) {
            $scope.change({
                media_ids: $scope.medias.map(media => media.id)
            });
        }
    };
};

module.exports = () => {
    return {
        scope: {
            'change': '&',
            'medias': '=',
            'type': '@',
        },
        restrict: "EA",
        replace: true,
        controller: [
            '$scope',
            '$element',
            'MediaService',
            'ModalService',
            PhotoSelectorDirective
        ],
        templateUrl: '/assets/panel/tpl/directives/photos-selector.html'
    };
};