let ReviewCategoryEditComponent = function(
    ReviewCategoryService,
    LanguageService,
    FormBuilderService,
    $timeout,
) {
    let $ctrl = this;

    $ctrl.reviewCategories;
    $ctrl.reviewCategory;
    $ctrl.languages;
    $ctrl.language = 'nl';

    let loadResources = () => {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        if ($ctrl.reviewCategoryId) {
            return ReviewCategoryService.read($ctrl.reviewCategoryId).then((res) => {
                $ctrl.reviewCategory = res.data.data;
                $ctrl.loadReviewCategory($ctrl.reviewCategory);
            });
        }

        $ctrl.reviewCategory = false;

        $ctrl.loadReviewCategory({});
    };

    $ctrl.submit = () => {
        
    }

    $ctrl.loadReviewCategory = function(reviewCategory) {
        let values = ReviewCategoryService.apiResourceToForm(reviewCategory);

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let promise;

            if ($ctrl.reviewCategory) {
                promise = ReviewCategoryService.update(
                    $ctrl.reviewCategory.id,
                    form.values
                )
            } else {
                promise = ReviewCategoryService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data) {
                    pushNotifications.push('success', 'check', 'Saved', 4000);

                    $timeout(function() {
                        document.location = res.data.data.url.admin_list;
                    }, 1000);
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();
            });
        });

        if (!Array.isArray($ctrl.blocks)) {
            $ctrl.blocks = [];
        }
    }

    $ctrl.$onInit = function() {
        $ctrl.reviewCategories = false;
        $ctrl.reviewCategory = false;
        
        loadResources();
    };
};

module.exports = {
    bindings: {
        'reviewCategoryId': '<'
    },
    controller: [
        'ReviewCategoryService',
        'LanguageService',
        'FormBuilderService',
        '$timeout',
        ReviewCategoryEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/review-category-edit.html'
};