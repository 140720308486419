let ReviewEditComponent = function (
    $state,
    $stateParams,
    ReviewService,
    MediaService,
    ModalService,
    FormBuilderService,
    $timeout
) {
    let $ctrl = this;

    $ctrl.media;
    $ctrl.states;
    $ctrl.language = 'en';
    $ctrl.debug = false;

    $ctrl.$onInit = function () {
        $ctrl.states = ReviewService.states();

        if ($ctrl.reviewId) {
            return ReviewService.read($ctrl.reviewId).then((res) => {
                $ctrl.review = res.data.data;
                $ctrl.loadReview($ctrl.review);
                $ctrl.setActiveStars($ctrl.form.values.stars);
            });
        }

        $ctrl.review = false;

        $ctrl.loadReview({
            state: $ctrl.states[0].id
        });

    };

    $ctrl.selectStars = (stars) => {
        $ctrl.setActiveStars(stars);
        $ctrl.form.values.stars = stars;
    };

    $ctrl.setActiveStars = (stars) => {

        $('.stars-block .star').each(function() {
            let starImage = '/assets/panel/img/' + ($(this).data('index') <= stars ?
                'star-active.png' : 'star-normal.png');

            $(this).attr('src', starImage);
        });
    };

    $ctrl.loadReview = (review) => {
        let values = ReviewService.apiResourceToForm(review);

        $ctrl.form = FormBuilderService.build(values, (form) => {
            form.lock();

            let promise;

            if ($ctrl.review) {
                promise = ReviewService.update(
                    $ctrl.review.id,
                    form.values
                )
            } else {
                promise = ReviewService.store(
                    form.values
                )
            }

            promise.then((res) => {
                if (res.data.data && res.data.data.url) {
                    if($ctrl.review.redirect){
                        document.location = res.data.data.url.admin_list;
                    } else if(!$ctrl.review){
                        pushNotifications.push('success', 'check', 'Saved', 4000);

                        $timeout(function() {
                            document.location = res.data.data.url.admin_edit;
                        }, 1000);
                    } else {
                        pushNotifications.push('success', 'check', 'Saved', 4000);
                    }
                }

                form.unlock();
            }, (res) => {
                form.errors = res.data.errors;
                form.unlock();

                $timeout(function() {
                    let topPositions = [],
                        topPosition = 0;

                    angular.element('.form-error').each( (index, error_element) => {
                        topPositions.push(angular.element(error_element).offset().top);
                    });

                    topPositions.sort();
                    topPosition = topPositions[0];

                    angular.element(document).scrollTop(topPosition - 80);
                }, 1000);
            });
        });

        if (review) {
            if (review.image) {
                MediaService.read(review.image.uid).then((res) => {
                    $ctrl.media = res.data.data;
                });
            }
        }
    };

    $ctrl.deletePhoto = () => {
        delete $ctrl.media;
        $ctrl.form.values.media_id = null;
    };

    $ctrl.selectPhoto = (media) => {
        $ctrl.media = media;
        $ctrl.form.values.media_id = $ctrl.media.id;
    };

    $ctrl.cancel = function () {
        $state.go('reviews', {});
    };
};

module.exports = {
    bindings: {
        'reviewId': '<'
    },
    controller: [
        '$state',
        '$stateParams',
        'ReviewService',
        'MediaService',
        'ModalService',
        'FormBuilderService',
        '$timeout',
        ReviewEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/review-edit.html'
};